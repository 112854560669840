<template lang="pug">
	b-modal#modal-analytics-help(ref='modal' size='md' hide-footer hide-header :centered='!isMobile')
		span.btn-close(aria-label="Close" @click="close")
			i.fontello.fontello-icon-cross
		
		h2.modal-xs-title.mb-2.font-weight-normal {{ $t('project.analytics.howToUse.metrics[0].title') }}
		div.lh-18.mb-4 {{ $t('project.analytics.howToUse.metrics[0].desc') }}
		
		b-btn.btn-long.btn-xs-100(variant='primary' @click='close') {{ $t('social.understand') }}
</template>


<script>
	import {mapGetters} from 'vuex'
	export default {
		name 		: 'ModalMetricsHelp',
		props 	: {
			item : {
				default: false,
				type: Boolean
			}
		},
		components: {
		
		},
		
		data : () => ({
		
		}),
		
		mounted() {
		},

		created () {
			
		},

		computed: {
			...mapGetters({
				project : 'project/opened'
			}),
			paymentLinkDefault () {
				return this.project ? `${this.paymentLinkDomain}${this.project.hashId}` : '';
			},
			paymentLink () {
				return this.project ? `${window.location.protocol}//${window.location.host.replace('admin\.','')}/${this.project.hashIdCustom}` : '';
			},
			paymentLinkDomain () {
				return `${window.location.protocol}//${window.location.host.replace('admin\.', '')}/`;
			},
		},

		methods: {
			close () {
				this.$refs.modal.hide();
			},
			show () {
				this.$refs.modal.show();
			}
		},

		watch : {
			item (v) {
				if ( v ) this.show();
			}
		}
	}
</script>
<style scoped lang='scss'>
	
</style>